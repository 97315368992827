/* eslint-disable array-callback-return */
import React from 'react';
import { useState } from 'react';

import Footer from '../../components/Footer';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby';
import Header from '../../components/Header/index';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import nounimg from '../../images/noun.svg';
import Group from '../../images/Group.svg';
import corporate from '../../images/corporate.svg';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';
// import useStyles from '../style';
import {
    Typography,
    Grid,
    Paper,
    Container,
    Divider,
    Box,
    Card,
    CardContent,
    Button,
    CardMedia,
    Stack
} from '@mui/material';
import { StaticImage } from 'gatsby-plugin-image';

import { makeStyles } from '@mui/styles';
const useStyles = makeStyles({
    careeresTypo: {
        padding: '2px',
        color: '#343333',
        fontSize: '14px !important',
        fontFamily: 'Poppins !important',
        letterSpacing: '0.27px !important',
        fontWeight: '500 !important',
        '@media (min-width: 320px) and (max-width:375px)': {
            textAlign: 'left'
        },
        '@media (min-width: 375px) and (max-width:420px)': {
            textAlign: 'left'
        },
        '@media (min-width: 420px) and (max-width:700px)': {
            textAlign: 'left'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            textAlign: 'left',
            fontSize: '10px !important'
        }
    },
    stack: {
        marginBottom: '0.1rem',
        '@media (min-width: 320px) and (max-width:375px)': {
            marginTop: '1rem',
            marginBottom: '1rem'
            //   marginTop:'1rem'
        },
        '@media (min-width: 375px) and (max-width:425px)': {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        '@media (min-width: 425px) and (max-width:700px)': {
            marginTop: '1rem',
            marginBottom: '1rem'
        },
        '@media (min-width: 768px) and (max-width:1024px)': {
            marginTop: 0,
            marginBottom: 0
        }
    }
});

const Careers = (props) => {
    const classes = useStyles();
    // eslint-disable-next-line no-unused-vars
    const [open, setOpen] = useState(false);
    const data = useStaticQuery(graphql`
        query MyQuery {
            allCareerJson {
                edges {
                    node {
                        description
                        language
                        heading
                        images {
                            childImageSharp {
                                gatsbyImageData
                            }
                        }
                    }
                }
            }
        }
    `);
    const CareersData = (data) => {
        const CareersArray = [];
        data.allCareerJson.edges.map((item, index) => {
            const thumb = getImage(item.node.images);

            CareersArray.push(
                <Grid key={index} item xs={6} md={4} sm={4} classes="grid_shadow"
                 
                >
                    <Card
                        variant="careers_card"
                       sx={{cursor: 'default'}}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={8} md={8} sm={8}>
                                <CardContent
                                    sx={{ flex: '1 0 auto', pr: '30px', pb: '30px', pt: '30px' }}
                                >
                                    <Typography variant="h3">{item.node.heading}</Typography>
                                </CardContent>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={4}
                                md={4}
                                sx={{
                                    width: '100%',

                                    paddingRight: '28px',
                                    PaddingBottom: '15px',

                                    display: 'flex',

                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <CardMedia
                                    sx={{
                                        width: '100%',
                                        height: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: 'auto'
                                    }}
                                >
                                    {/* <img src={img} /> */}
                                    <GatsbyImage image={thumb} alt="item.node.images" />
                                </CardMedia>
                            </Grid>
                        </Grid>
                    </Card>
                    <Box
                        sx={{
                            width: '30px',
                            height: '30px',
                            borderTop: '40px solid #D3F09A',
                            borderLeft: '40px solid transparent'
                        }}
                    ></Box>{' '}
                    <Grid>
                        <Paper variant="capability_banner_paper_careers"
                         sx={{cursor: 'default'}}
                        >
                            <Divider variant="card_carrer" />

                            <Button variant="view_button"
                            onClick={() => {
                            setOpen(true);

                            navigate(`/career/${item.node.language}`, {
                                replace: true,
                                state: { id: index }
                            });
                        }}
                            >
                                View
                                <Typography sx={{ marginTop: '5px', paddingLeft: '10px' }}>
                                    <StaticImage src="../../images/Icon.svg" placeholder="none" />
                                </Typography>
                            </Button>
                        </Paper>
                    </Grid>
                </Grid>
            );
        });
        return CareersArray;
    };

    return (
        <div>
            <Header />

            <Paper variant="careers">
                <StaticImage
                    placeholder="none"
                    src="../../images/mask.svg"
                    style={{
                        float: 'right',
                        position: 'absolute',
                        right: '0px',
                        maxHeight: '350px',
                        maxWidth: '350px',
                        zIndex: 0
                    }}
                />
                <Container sx={{ position: 'relative', zIndex: '99.9' }}>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{ zIndex: 10 }}
                    >
                        <Grid item xs={12} sm={1} md={1}></Grid>
                        <Grid item xs={12} sm={2} md={2} classes="careers_grid">
                            <StaticImage
                                src="../../images/Hiring.svg"
                                alt="mask"
                                placeholder="none"
                                // className={classes.careerImage}
                                style={{ paddingTop: '1rem' }}
                            />
                        </Grid>
                        <Grid item xs={12} md={9} sm={9} sx={{ pt: 10 }}>
                            <Typography variant="h4">
                                Come Join Our
                                <span style={{ color: '#BECE20' }}>
                                    {' '}
                                    <b>Journey</b>{' '}
                                </span>
                                <Stack
                                    direction={{ xs: 'column', sm: 'row' }}
                                    spacing={{ xs: 1, sm: 2, md: 3 }}
                                    variant="careers_stack"
                                    // sx={{ marginBottom: '0.1rem' }}
                                    className={classes.stack}
                                >
                                    <Typography className={classes.careeresTypo}>
                                        <LazyLoadImage
                                            src={nounimg}
                                            style={{ marginRight: '8px', marginBottom: '-10px' }}
                                            alt="LAZY"
                                        />
                                        Enabling Careers
                                    </Typography>

                                    <Typography className={classes.careeresTypo}>
                                        <LazyLoadImage
                                            src={Group}
                                            style={{ marginRight: '8px' }}
                                            alt="LAZY"
                                        />
                                        Catalyst For Social Impact
                                    </Typography>

                                    <Typography className={classes.careeresTypo}>
                                        <LazyLoadImage
                                            src={corporate}
                                            style={{ marginRight: '8px' }}
                                            alt="LAZY"
                                        />
                                        Work Culture
                                    </Typography>
                                </Stack>
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Paper>
            <Container>
                <Grid
                    container
                    spacing={{ xs: 2, md: 5, lg: 7 }}
                    columns={{ xs: 4, sm: 8, md: 12 }}
                    classes="careers_grid_section"
                    sx={{ pb: '1rem', mb: '1rem' }}
                >
                    {CareersData(data)}
                </Grid>
            </Container>
            <br />
            <Footer />
        </div>
    );
};

export default Careers;
